@import 'node_modules/@hse24/shared-components/styles/colors';

.show_info_container {
  display: flex;
  width: 100%;
  padding-left: 25px;

  .show_info {
    h4 {
      margin-bottom: 5px;
    }
  }

  .live {
    display: flex;
    align-items: center;
    height: max-content;
    padding: 5px;
    text-transform: uppercase;
    box-shadow: 0 2px 4px 0 rgba(37, 37, 37, 0.23);

    .point {
      width: 5px;
      height: 5px;
      background-color: $color--error-red;
      border-radius: 50%;
      margin-right: 5px;
    }

    h4 {
      margin: 0px;
    }
  }
}

.inOnAir {
  justify-content: space-evenly;
  padding-left: 0px;
}

@import 'node_modules/@hse24/shared-components/styles/colors';

.channel_card {
  background-color: $color--lightgrey-lightest;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
  & svg {
    height: 17px;
  }
  & p {
    font-size: 9px;
  }
}
.current_channel {
  background-color: $color--nightblue;
  color: white;
}

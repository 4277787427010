@import 'node_modules/@hse24/shared-components/styles/colors';

.side_bar_container {
  height: 100vh;
  width: 22vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .divider {
    color: transparent;
  }

  .go_to_air_product {
    width: 80%;
    text-transform: uppercase;
  }
}

@import 'node_modules/@hse24/shared-components/styles/colors';

.wrapper {
  margin: 0 auto;
  padding: 25px;
  width: 100%;
  height: calc(100vh - 102px);
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.tv_shows_container {
  width: 100%;
  height: 100%;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title_bar {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  & * {
    margin: 0;
  }
  & h3 {
    text-transform: capitalize;
    font-size: 1.75rem;
    font-weight: 500;
    color: #ffffff;
  }
}

.date_picker {
  border: 2px solid $color--lightgrey;
  width: fit-content;
  height: fit-content;
  padding: 13px 33px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  display: flex;
  appearance: menulist-button;
  &:hover {
    background-color: $color--lightgrey-lightest;
  }
}

@import '~@hse24/shared-components/styles/colors';

.container {
  width: 100%;
  background-color: #f4f7f9;
  padding: 10px;
  table-layout: fixed;
  width: 100%;

  td,
  th {
    padding: 10px;
    word-break: break-word;
  }

  td:first-child {
    font-size: 16px;
    font-weight: 700;
    color: $color--brand-orange;
  }
  tr:nth-child(2),
  tr:nth-child(4),
  tr:nth-child(5) {
    td {
      border-bottom: 2px solid #000;
    }
  }
  th {
    background-color: $color--nightblue;
    color: $color--brand-orange;
    font-size: 18px;
    font-weight: 700;
  }
}

@import '~@hse24/shared-components/styles/colors';

.product_table_container {
  background-color: white;
  height: 50vh;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  overflow: auto;

  table {
    width: 100%;

    th,
    td {
      text-align: left;
      border-bottom: solid 1px $color--lightgrey;
    }
  }
}

@import '~@hse24/shared-components/styles/colors';

.pointer {
  cursor: pointer;
}

.product_card {
  border-top: 1px solid $color--lightgrey;
  height: 83px;
  .main_info {
    display: flex;
    & * {
      margin: 0;
    }
    & img {
      margin: 0;
      max-width: 50px;
      object-fit: contain;
      align-self: flex-start;
    }
    .info {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      & * {
        text-align: left;
      }
      & h5 {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .air_time {
      display: flex;
      flex-direction: row;

      b {
        margin-right: 5px;
      }
    }
  }
  .price {
    display: flex;
    flex-direction: column;
  }

  .stock {
    display: flex;
    flex-direction: column;
  }

  .air_time {
    display: flex;
    flex-direction: row;

    b:first-child {
      margin-right: 25px;
    }
  }
}

.base_no {
  position: relative;
  height: 83px;
  display: flex;
  align-items: center;

  .on_air {
    position: absolute;
    top: 0px;
    left: -20px;
    padding: 5px 3px;
    width: max-content;
    font-size: 12px;
    background-color: $color--sale-red;
    color: white;
  }
}
.toggled {
  background-color: $color--lightgrey-lightest;
}

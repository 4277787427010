.channels {
  width: 100%;
  display: flex;
  justify-content: center;
}

.channels_wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
  height: 60px;
}

@import '~@hse24/shared-components/styles/colors';

.show_row {
  width: 100%;
  height: 73px;
  display: flex;
  gap: 5px;
}

.hour {
  width: 100px;
  background-color: $color--lightgrey-lightest;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program_card {
  cursor: pointer;
  background-color: $color--lightgrey-lightest;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: all 0.2s ease;
  & p {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .products_live {
    gap: 15px;
    justify-content: flex-start;
  }
  .host {
    justify-content: flex-end;
  }

  &:hover {
    background-color: $color--lightgrey-mid;
  }
}

.live {
  background-color: $color--nightblue;
  color: white;
  .products_live {
    & span {
      background-color: white;
      color: $color--nightblue;
      font-weight: 600;
      padding: 3px 10px;
    }
  }
  &:hover {
    background-color: $color--nightblue;
  }
}

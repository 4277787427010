@import '~@hse24/shared-components/styles/colors';

.container {
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  background-color: $color--lightgrey-lightest;

  .table_wrapper {
    display: flex;
    width: 60vw;
    flex-direction: column;
    justify-content: space-between;
    height: 95vh;
    margin: auto 0;
  }
}
.loading_error {
  flex-direction: column-reverse;
}

.loading,
.error {
  height: 95vh;
  text-align: center;
}

.table_loading_error {
  align-self: end;
  margin: 10px 20px !important;
}

@import '~@hse24/shared-components/styles/colors';

.variant_card {
  border-top: 1px solid $color--lightgrey;
  padding-left: 20px;
  width: 100%;
  & td {
    text-align: center;
  }
  .sku {
    min-width: 100px;
    text-align: left;
  }
  .price {
    display: flex;
    flex-direction: column;
  }
  .stock {
    display: flex;
    flex-direction: column;
  }
  .basic_info {
    display: flex;
    gap: 10px;
    & img {
      margin: 0;
      max-width: 40px;
      object-fit: contain;
      align-self: flex-start;
    }
    .dimensions {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .dim {
        display: flex;
        gap: 10px;
      }
    }
  }
}

.color_circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

@import '~@hse24/shared-components/styles/colors';

.table_header {
  th {
    width: auto;
    color: $color--brand-orange;
    font-weight: 700;
  }

  th:nth-child(3) {
    padding-right: 120px;
  }

  th:nth-child(1) {
    padding-right: 60px;
  }

  .icon_container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;

    span {
      margin-right: 5px;
    }

    svg {
      color: black;
    }

    .icon {
      opacity: 0;
    }

    .hover_icon {
      opacity: 0.5;
    }
  }
}

@import '~@hse24/shared-components/styles/colors';

.variants_table {
  height: 40vh;
  width: 100%;
  padding: 20px;
  background-color: white;
  overflow: auto;

  table {
    width: 100%;

    th,
    td {
      text-align: left;
      border-bottom: solid 1px $color--lightgrey;
    }
  }
}

body {
  margin: 0;
  background: #1B1C1D !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ui.basic.segment{
  background: #FFFFFF
}
.ui.segment{
  border-radius: 0
}

.noPadding {
  padding: 0;
}
.noPaddingTopBottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.noMargin {
  margin: 0 !important;
}
.ui.cards > .card > .content > .header:not(.ui), .ui.card > .content > .header:not(.ui) {
  font-size: 1em;
}
select {
  box-shadow: 0px 0px 0px 2px #FF851B inset !important;
  background: transparent none;
  color: #FF851B;
  border-radius: 10em;
  font-size: 0.85714286rem;
  cursor: pointer;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0em 0.25em 0em 0em;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: bold;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  width: 80%;
  text-indent: 40%;
}

.hseorange {
  color: #ff3c28 !important;
}

.ui.hseorange.labels .label, .ui.hseorange.label {
  background-color: #ff3c28 !important;
  border-color: #ff3c28 !important;
  color: #FFFFFF !important;
}

.ui.orange.header {
  color: #ff3c28 !important;
}
a.ui.orange.header:hover {
  color: #f26202 !important;
}
.ui.orange.dividing.header {
  border-bottom: 2px solid #ff3c28;
}

/* Inverted */
.ui.inverted.orange.header {
  color: #FF851B !important;
}
a.ui.inverted.orange.header:hover {
  color: #ff7701 !important;
}

select option {
  background: #1B1C1D;
  color: #FFFFFF;
}
.smallChart > .chart{
  height: 18em;
}
@media (min-width: 700px){
  .extraPaddingResponsive{
    padding-top: 3.5rem;
  }
}
@media only screen and (max-width: 767px){
  .ui.stackable.grid > .noPadding:not(.row)
  {
    padding: 0 !important;
  }
  .noPaddingTop {
    padding-top: 0 !important;
  }
  .noPaddingBottom {
    padding-bottom: 0 !important;
  }
  .ui.divided.list.secondShowTable > .item:first-child {
    padding-top: 0.42857143em;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
  }
  .ui.divided.list.firstShowTable > .item:last-child {
    padding-bottom: 0.42857143em;
  }


}

@media only screen and (max-width: 320px){
  .progressLabel > .label{
    font-size: 0.8em !important;
  }
  .ui.menu .item {
    font-size: 0.9em !important;
  }

}

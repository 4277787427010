@import '~@hse24/shared-components/styles/colors';

.container {
  width: 100%;
  height: 83vh;
  overflow: auto;
  margin: 0 auto;
  background-color: #ffffff;

  .table_container {
    width: 95%;
    margin: auto;
    text-align: left;
    padding: 30px;
    background-color: #ffffff;

    td {
      width: auto;
      padding: 20px 0px;
      border-bottom: solid 1px $color--lightgrey;
    }
  }
}

@import 'node_modules/@hse24/shared-components/styles/colors';

.details_container {
  display: flex;
  background-color: $color--lightgrey-light;
}

.body_container {
  width: 100%;
  height: 100vh;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

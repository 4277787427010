@import '~@hse24/shared-components/styles/colors';

.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .date {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 3;
    align-items: center;
    margin: 20px 0px;

    h4 {
      margin: 0;
    }
  }

  .date_picker {
    border: 2px solid $color--lightgrey;
    width: 70%;
    height: fit-content;
    padding: 13px 33px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.2s ease;
    display: flex;
    appearance: menulist-button;
    &:hover {
      background-color: $color--lightgrey-lightest;
    }
  }

  .button {
    width: 20%;
  }
}

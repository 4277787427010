@import '~@hse24/shared-components/styles/colors';

.select_container {
  width: 80%;
  margin: 20px auto;
  position: relative;
  .select_trigger {
    width: 100%;
    height: 35px;
    display: flex;
    & span:first-child {
      width: 127px;
      justify-content: unset;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .select_wrapper {
    z-index: 999;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 32px -8px rgba(37, 37, 37, 0.23);
    min-width: 400px;
    height: 500px;
    overflow-y: auto;
    & div {
      padding: 8px 16px;
      cursor: pointer;
      &:hover {
        background-color: $color--lightgrey-lightest;
      }
    }
    .selected {
      background-color: $color--lightgrey-lightest;
    }
  }
}

@import '~@hse24/shared-components/styles/colors';

.container {
  max-width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 95vh;
  background-color: #ffffff;
  border-radius: 5px;
  margin: auto 0;

  h1 {
    min-height: auto;
    text-align: center;
  }

  .date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
    margin: 20px;

    h4 {
      margin: 0;
    }
  }
  .agency_wrapper {
    min-height: 30%;
    overflow: auto;
    width: 90%;
    border: 1px solid #dce6eb;
  }
}

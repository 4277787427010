@import '~@hse24/shared-components/styles/colors';

.card_container {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  color: $color--brand-orange;
  border-bottom: 1px solid $color--lightgrey;
  border-top: 1px solid $color--lightgrey;

  background-color: #ffffff;
}
.selected_card {
  background-color: $color--nightblue;
}

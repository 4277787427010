@import 'node_modules/@hse24/shared-components/styles/colors';

.revenue_container {
  display: flex;
  width: 90%;
  gap: 20px;
  height: max-content;
  padding: 10px;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(37, 37, 37, 0.23);
  margin-top: 20px;

  .expected_revenue,
  .generated_revenue {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h4 {
      padding: 0px 5px;
    }
  }

  .generated_revenue {
    flex: 1;

    .price {
      font-size: 20px;
      font-weight: 900;
      color: $color--winning;
    }
  }

  .expected_revenue {
    span {
      font-size: 15px;
      font-weight: 700;
    }
  }

  .divider {
    width: 0;
    height: 80%;
    border-color: $color--lightgrey-light;
  }
}
